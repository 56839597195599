



























































































































import { Business, Role, Voucher } from "@/types";
import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import voucherStoreModule from "@/store/modules/voucher";

const { mapActions: voucherActions, mapGetters: voucherGetters } =
  createNamespacedHelpers("VOUCHERS");

export default Vue.extend({
  name: "Vouchers",
  data: () => ({
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Value", value: "value" },
      { text: "Action", value: "action" },
    ],
    options: { page: 1 } as { page: number },
    voucher: undefined as undefined | Voucher,
    showDeleteDialog: false,
  }),
  watch: {
    options: {
      handler() {
        this.fetchVouchers();
      },
      deep: true,
    },
    role: "fetchVouchers",
  },
  created() {
    this.fetchVouchers();
  },
  computed: {
    ...voucherGetters(["voucherPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...voucherActions(["fetchVoucherList", "deleteVoucher"]),
    fetchVouchers() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        this.fetchVoucherList(`?businessId=${bid}&page=${this.options.page}`);
      }
    },
  },
  beforeCreate() {
    const namespace = `VOUCHERS`;
    if (!this.$store.hasModule(namespace)) {
      this.$store.registerModule(namespace, voucherStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("VOUCHERS");
  },
});
